import React, { useState, useEffect } from 'react';
import P403 from '../authorization/403';
import P500 from '../authorization/500';

const Met_sales = () => {
    const [showError403, setShowError403] = useState(false);
    const [showError500, setShowError500] = useState(false);

    useEffect(() => {
      const fetchUserData = async () => {
        const token = localStorage.getItem('Token');
        //console.log(token);
        if (token) {
          try {
            
            const response = await fetch('https://learninglive.ru:5000/api/prava', {
              headers: {
                authorization: token,
                surl: 'met_sales',
              },
            });
            //console.log('+++++++++++++++++++',response.status, showError403);
            if (response.status === 200) {
              // Continue with page loading
            } else if (response.status === 403) {
              // Display 403 error page
            
              setShowError403(true);
              
            } else if (response.status === 500) {
              // Display 500 error page
              setShowError500(true);
            } else {
              // Handle other status codes as needed
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error state as needed
          }
        } else {
          console.error('No token found in local storage');
          // Handle token absence as needed
        }
      };
  
      fetchUserData();
    }, []);
  
    return (
      <>
        {/* Условный рендеринг в зависимости от состояний ошибок */}
        {showError403 ? (
          <P403 />
        ) : showError500 ? (
          <P500 />
        ) : (
          <>Нормальное содержимое</>
        )}
      </>
    );
  };
  

export default Met_sales