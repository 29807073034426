import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LeftSide from './Page/LeftSide';
import Profile from './Page/Profile';
import Rooms from './Page/Rooms';
import LeftSideBoot from './Page/LeftSideBoot'
import Rooms_setup from './Page/Rooms_setup';
import Deals from './Page/Deals';
import Customers from './Page/Customers';
import Setting from './Page/Setting';
import Getutm from './Page/Getutm';
import Audio from './Page/Audio';
import Screencast from './Page/Screecast';
import Analitics from './analitics/analitics';
import Freeaudio from './Page/Freeaudio';
import { AppContext } from './utils/AppContext';
import Metodist from './metodist/metodist';
import Met_group from './metodist/met_group';
import Met_sales from './metodist/met_sales';
import Met_users from './metodist/met_users';
import p403 from './authorization/403';
import p401 from './authorization/401';
import p500 from './authorization/500';
import Webinar_Moder from './Webinars/Web_moder';



const Main = ({ onLogout, isLoggedIn }) => {
  const { setPostData } = useContext(AppContext);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('Token');
    if (token) {
      setIsAuthenticated(true);
    
    } else {
      setIsAuthenticated(false);
      navigate('/authorization'); // Redirect to the authorization page if the user is not authenticated
    }
  }, [navigate, setPostData]);

  const handleLogout = () => {
    sessionStorage.removeItem('Token');
    setIsAuthenticated(false);
    navigate('/authorization');
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {isAuthenticated && <LeftSideBoot onLogout={handleLogout} isLoggedIn={isAuthenticated} />}
      <div style={{ flex: 1, padding: '20px', height: '100%', overflowY: 'auto' }}>
        <h1>Проект: ****</h1>
        {isAuthenticated ? (
          <div>
            <p>Добро пожаловать</p>
          </div>
        ) : (
          <p>Вы не авторизованы. Войдите заново.</p>
        )}

        <Routes>
          {isAuthenticated && (
            <>
              <Route path="/profile" element={<Profile isAuthenticated={isAuthenticated} />} />
              <Route path="/rooms" element={<Rooms isAuthenticated={isAuthenticated} />} />
              <Route path="/rooms/rooms_setup" element={<Rooms_setup isAuthenticated={isAuthenticated} />} />
              <Route path="/web_moder" element={<Webinar_Moder isAuthenticated={isAuthenticated} />} />
              <Route path="/deals" element={<Deals isAuthenticated={isAuthenticated} />} />
              <Route path="/customers" element={<Customers isAuthenticated={isAuthenticated} />} />
              <Route path="/setting" element={<Setting isAuthenticated={isAuthenticated} />} />
              <Route path="/getutm" element={<Getutm isAuthenticated={isAuthenticated} />} />
              <Route path="/audio" element={<Audio isAuthenticated={isAuthenticated} />} />
              <Route path="/screencast" element={<Screencast isAuthenticated={isAuthenticated} />} />
              <Route path="/metodist" element={<Metodist isAuthenticated={isAuthenticated} />} />
              <Route path="/met_group" element={<Met_group isAuthenticated={isAuthenticated} />} />
              <Route path="/met_sales" element={<Met_sales isAuthenticated={isAuthenticated} />} />
              <Route path="/met_users" element={<Met_users isAuthenticated={isAuthenticated} />} />
              <Route path="/analitics" element={<Analitics isAuthenticated={isAuthenticated} />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
};

export default Main;