import React, { useState, useEffect } from 'react';
//import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Input, Button, Upload, message } from 'antd';
import { UserOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, Switch, Avatar } from 'antd';
import { useHistory, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import WebinarModer from '../Webinars/Web_moder';




//const [userData, setUserData] = useState(null);





function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
// Последнее 17
const items = [
  getItem('CRM', 'sub1', <MailOutlined />, [
    getItem('Заказы', '1'),
    getItem('Пользователи', '2'),
    getItem('Option 3', '3'),
    getItem('Option 4', '4'),
    getItem('Методисты', 'sub5', null, [getItem('Пользователи', '15'), getItem('Продажи', '16'), getItem('Группы', '17')]),
  ]),
  getItem('Вебинары', 'sub2', <AppstoreOutlined />, [
    getItem('Настройка', '5'),
    getItem('Пока нет', '6'),
    getItem('Видеомонтаж', '14'),
    getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
  ]),
  getItem('Аналитика', 'sub5', <AppstoreOutlined />, [
    getItem('Геткурс', '18'),
  ]),
  getItem('Настройки', 'sub4', <SettingOutlined />, [
    getItem('Профиль', '9'),
    getItem('Параметры', '10'),
    getItem('Getcourse UTM', '11'),
    getItem('Аудио', '13'), 
    getItem('Выход', '12'),
  ]),
];

function getMenuItems(right) {
  let numericRight = parseInt(right);
  //console.log('Numeric Right:', numericRight);

  if (isNaN(numericRight)) {
    console.error('Invalid input for "right". Must be a number.');
    return [];
  }

  let menu = [];

  if (numericRight === 0) {
    menu = items;
  } else if (numericRight === 100) {
    const sub4Item = items.find((item) => item.key === 'sub4');
    if (sub4Item) {
      const sub4Children = sub4Item.children;
      const filteredChildren = sub4Children.filter((child) => ['9', '12'].includes(child.key));
      menu = [{...sub4Item, children: filteredChildren}];
    } else {
      console.error('Menu item with key "sub4" not found.');
    }
  }

  //console.log('Filtered Menu:', menu);
  return menu;
}






const App = ({ onLogout, isLoggedIn }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn);
  const navigate = useNavigate();
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
  const [openKeys, setOpenKeys] = useState([]);
  const [photo, setPhoto] = useState(null); // Add state for photo
  const [right, setRight] = useState(100);
  const [filteredMenu, setFilteredMenu] = useState([]); // Add state for filtered menu


  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('Token');
      if (token) {
        try {
          const response = await fetch('https://learninglive.ru:5000/api/user', {
            headers: {
              Authorization: `${token}`,
            },
          });
          const userData = await response.json(); // Parsing response to JSON
          //console.log(userData.users[0].id_role);
          // Здесь мы присваиваем права в зависимости от которых будем показывать меню
          setRight(userData.users[0].id_role);
          const menuItems = getMenuItems(userData.users[0].id_role); // Получаем отфильтрованные пункты меню
          setFilteredMenu(menuItems); // Устанавливаем отфильтрованное меню для отображения
          //console.log(menuItems);
          const photoUrl = arrayBufferToBase64(userData.users[0].foto.data);
          setPhoto(photoUrl);  // Setting the photo state with the fetched photo
          //console.log('Вот фото', photoUrl);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        console.error('No token found in local storage');
      }
    };

    fetchUserData();
  }, []);


  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    //console.log(' Вот байты ', buffer);
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
  
    return `data:image/jpeg;base64,${window.btoa(binary)}`; // Assuming the image is JPEG, adjust accordingly if needed
  }


  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  


  const handleClick = (e) => {
    // Обработчик клика по элементам меню
    //console.log('Clicked: ', e.key);
    if (e.key === '1') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/deals');
    }
    if (e.key === '2') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/customers');
    }



    if (e.key === '5') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/rooms');
    }
    if (e.key === '6') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      
    }
    if (e.key === '9') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/profile');
    }
    if (e.key === '10') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/setting');
    }
    if (e.key === '11') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/getutm');
    }

    if (e.key === '13') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/audio');
    }
    if (e.key === '14') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/screencast');
    }
    if (e.key === '15') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/met_users');
    }
    if (e.key === '16') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/met_sales');
    }
    if (e.key === '17') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/met_group');
    }
    if (e.key === '18') {
      //history.push('/profile'); // Импортируйте history из react-router-dom
      navigate('/analitics');
    }

    if (e.key === '12') {
      //history.push('/profile'); // Импортируйте history из react-router-dom

      localStorage.removeItem('Token');
      setIsAuthenticated(false);
      if (typeof onLogout === 'function') {
        onLogout();
      }
      navigate('/autorization');
    }
  }; 
  useEffect(() => {
    const token = localStorage.getItem('Token');
    if (token) {
      //setIsAuthenticated(true);
    } else {
      //setIsAuthenticated(false);
    }
  }, [isLoggedIn]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0 16px', justifyContent: 'center' }}>
      <Avatar size={120} shape="square" icon={<UserOutlined />} src={photo} /> 
    </div>
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{
        width: 256,
      }}
      //items={items}
      items={filteredMenu} // Заменяем items на отфильтрованный список меню
      onClick={handleClick} // добавлен обработчик клика
    />
    </div>
  );
};
export default App;
