import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import { Input, Form, Switch, Image, Table } from 'antd';
import { Button } from "antd";
import P403 from '../authorization/403';
import P500 from '../authorization/500';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import fetchUserData from '../components/apiPrava'; // Импорт функции из созданного модуля

const { TabPane } = Tabs;

const { TextArea } = Input;




function Rooms_setup() {
  const [showError403, setShowError403] = useState(true);
  const [showError500, setShowError500] = useState(false);
  const [data, setData] = useState([]); // Состояние для данных
  const [formData, setFormData] = useState({
    url_id: '',
    name: '',
    autor: '',
    url_for_users: '',
    date_web: '',
    url_video: '',
    html_cod: '',
    html_cod_sales: '',
});
const [activeTab, setActiveTab] = useState('setup');
const [size, setSize] = useState('large');


const data_button = [
  {
    key: '1',
    buttonNumber: '1',
    buttonName: 'Button 1',
    buttonLink: 'https://www.example.com/button1',
    buttonEnabled: true,
  },
  {
    key: '2',
    buttonNumber: '2',
    buttonName: 'Button 2',
    buttonLink: 'https://www.example.com/button2',
    buttonEnabled: false,
  },
  // Add more data as needed
];

const columns = [
  {
    title: 'Button Number',
    dataIndex: 'buttonNumber',
    key: 'buttonNumber',
  },
  {
    title: 'Button Name',
    dataIndex: 'buttonName',
    key: 'buttonName',
    render: (buttonName) => (
      <Input value={buttonName} />
    ),
  },
  {
    title: 'Button Link',
    dataIndex: 'buttonLink',
    key: 'buttonLink',
    //render: (text) => <a href={text}>{text}</a>,
    render: (buttonLink) => (
      <Input value={buttonLink} />
    ),
  },
  {
    title: 'Button Enabled',
    dataIndex: 'buttonEnabled',
    key: 'buttonEnabled',
    render: (buttonEnabled) => (
      <Switch checked={buttonEnabled} />
    ),
  },
];



  useEffect(() => {
    const fetchData = async () => {
      const surl = 'rooms_setup'; // значение для surl
      const { data, status, error } = await fetchUserData(surl);
      //setData(result);
          
      //console.log(status);
          //console.log('+++++++++++++++++++',response.status, showError403);
          if (status === 200) {
            // Continue with page loading
            setShowError403(false);
          } else if (status === 403) {
            // Display 403 error page
          
            setShowError403(true);
            
          } else if (status === 500) {
            // Display 500 error page
            setShowError500(true);
          } else {
            // Handle other status codes as needed
          }
      
      
    };

    fetchData();
  }, []);


  useEffect(() => {
    // получаем данные комнаты
    const fetchUserData = async () => {
      const token = localStorage.getItem('Token');
      //console.log(token);
      if (token) {
        try {
          const urlParams = new URLSearchParams(window.location.search);
          const idr = urlParams.get('id');
          const response = await fetch(`https://learninglive.ru:5000/api/room/${idr}`, {
            headers: {
              authorization: token,
            },
          });
          const result = await response.json();
          console.log(response.status);
          //const data = response.data;
          if (response.status === 200) {
            setFormData(result); // Передаем весь объект JSON непосредственно в setFormData
          } else {
            setShowError500(true);
            console.error('An error occurred while retrieving project data: ' + data.message);
          }
                    //console.log('+++++++++++++++++++',response.status, showError403);
          
        } catch (error) {
          setShowError500(true);
          console.error('Error fetching user data:', error);
          // Handle error state as needed
        }
      } else {
        console.error('No token found in local storage');
        // Handle token absence as needed
      }
    };

    fetchUserData();
  }, []);

  const handleSave = () => {
    // Add your save logic here
    // For example, sending a request to save data
   }

   function goBack() {
    window.history.back();
}


function formatDateForInput(datetime) {
  const date = new Date(datetime);
  const year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

   
  return (
    <>
    {/* Условный рендеринг в зависимости от состояний ошибок */}
    {showError403 ? (
      <P403 />
    ) : showError500 ? (
      <P500 />
    ) : (


    <div className="container_room" style={{ height: '750px', width: '900px', padding: '10px', backgroundColor: '#FFFFFF' }}>
      <div className="form-container_room" style={{ height: '700px' }}>
              <div>
              <Button type="primary" onClick={goBack}>Обратно</Button>
              
              </div>
              <p>   </p>
              <Tabs defaultActiveKey="1" type="card" size={size} style={{ border: '1px solid #000', borderRadius: '5px', padding: '20px' }} >

                <TabPane tab="Настройки" key="1" >
                        <div className="tab-group_room"  style = {{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                              <div style={{ width: '300px'}}>
                                <label htmlFor="url_id">URL ID:</label>
                                <Input type="text" id="url_id" name="url_id" value={formData.url_id} />
                              </div>
                              <div style={{ width: '600px'}}>
                                <label htmlFor="name">Название:</label>
                                <Input type="text" id="name" name="name"  value={formData.name}/>
                              </div>
                              <div style={{ width: '600px'}}>
                                <label htmlFor="autor">Автор:</label>
                                <Input type="text" id="autor" name="autor"  value={formData.autor}/>
                              </div>
                              <div style={{ width: '600px'}}>
                                <label htmlFor="url_for_users">URL для пользователей:</label>
                                <Input type="text" id="url_for_users" name="url_for_users"  value={formData.url_for_users}/>
                              </div>
                              <div style={{ width: '300px'}}>
                                <label htmlFor="date_web">Дата вебинара:</label>
                                <Input type="datetime-local" id="date_web" name="date_web"  value={formatDateForInput(formData.date_web)}/>
                              </div>
                              <div style={{ width: '600px'}}>
                                <label htmlFor="url_video">URL для видео:</label>
                                <Input type="text" id="url_video" name="url_video"  value={formData.url_video}/>
                              </div>
                              <div style={{ width: '600px'}}>
                                <label htmlFor="html_cod">HTML код на время трансляции:</label>
                                <TextArea rows={4} placeholder="html_cod" maxLength={1500} value={formData.html_cod}/>
                              </div>
                              <div>
                              <Button type="primary" onClick={handleSave}>Сохранить</Button>
                              </div>
                        </div>
                  </TabPane>
                  <TabPane tab="Продажи" key="2" >
                              <h3> Баннер </h3>
                                  <div>
                                  <Switch defaultChecked />
                                  </div>
                                  <div>
                                    <p>  </p>
                                  </div>
                                  <div>
                                  <input type="file" />
                                  </div>
                                  <div>
                                  <Image
                                    height={100}
                                    src="https://avatars.mds.yandex.net/get-mpic/5323357/img_id6972637756536730956.jpeg/orig"
                                  />
                                </div>
                                
                              <h3> Кнопки </h3>
                                <Table columns={columns} dataSource={data_button} />;
                              <h3> HTML код </h3>
                              <Switch defaultChecked />
                              <div>
                              <div style={{ width: '600px'}}>
                                <label htmlFor="html_cod_sales">HTML код продажи:</label>
                                <TextArea rows={4} placeholder="html_cod_sales" maxLength={1500} value={formData.html_cod_sales}/>
                              </div>
                              <div>
                                    <p>  </p>
                              </div>
                              <Button type="primary" onClick={handleSave}>Сохранить</Button>
                              </div>
                  </TabPane>
                
                  <TabPane tab="Статистика" key="3" >
                              <div>
                              <Button type="primary" onClick={handleSave}>Сохранить</Button>
                              </div>

                  </TabPane>
                  <TabPane tab="Чат" key="4" >
                              <h3> Настройки чата </h3>
                                  <div>
                                    <> Включение чата :    </>
                                  
                                  <Switch defaultChecked />
                                  </div>
                                  <div>
                                    <p>  </p>
                                  </div>
                              <div>
                              <Button type="primary" onClick={handleSave}>Сохранить</Button>
                              </div>

                  </TabPane>                  
                  <TabPane tab="Интеграции" key="5" >
                              <h3> Настройки интеграций </h3>
                              <div>
                                    <> Отправка в GETCOURSE :    </>
                                  
                                  <Switch defaultChecked />
                                  </div>
                                  <div>
                                    <p>  </p>
                                  </div>
                                  <div>
                                  <label htmlFor="url_video">Код уникального предложения:</label>
                                    <Input type="text" />
                                  </div>
                                  <div>
                                    <p>  </p>
                                  </div>

                              <div>
                              <Button type="primary" onClick={handleSave}>Сохранить</Button>
                              </div>


                  </TabPane>                  
                </Tabs>
 

      </div>
    </div>
  )}
</>
  );
}


export default Rooms_setup;
