import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Input, Tabs } from 'antd';
import {  Link, useNavigate  } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
//import { DownloadOutlined } from '@ant-design/icons';
//import { Button, Divider, Flex, Radio } from 'antd';
//import type { ConfigProviderProps } from 'antd';
import axios from 'axios'; // Don't forget to import axios
import { ThemesContext } from 'react-admin';
import P403 from '../authorization/403';
import P500 from '../authorization/500';
import fetchUserData from '../components/apiPrava'; // Импорт функции из созданного модуля

const { TabPane } = Tabs;



const WebinarRooms = () => {
  const [showError403, setShowError403] = useState(true);
  const [showError500, setShowError500] = useState(false);
  const [data, setData] = useState([]); // Состояние для данных

  const [webinarData, setWebinarData] = useState([]);

  const [visible, setOpen] = useState(false);
  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('setup');


  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    // Ваша логика обработки нажатия кнопки "Отмена"
    setOpen(false);
  };


  const handleOk = async () => {
    const token = localStorage.getItem('Token');
  
    if (token) {
      const url = `https://learninglive.ru:5000/api/createroom`;
  
           // Проверяем, что в поле "urlroom" только английские символы
      const urlroomValidation = /^[a-zA-Z0-9-_]+$/;
      const isUrlroomValid = urlroomValidation.test(inputValue2);
  
      if (isUrlroomValid) {
        try {
          const response = await axios.put(url, null, {
            headers: {
              'authorization': token,
              'name': encodeURIComponent(inputValue1),
              'urlroom': encodeURIComponent(inputValue2)
            }
          });
  
          const data = response.data;
  
          if (data && data.success === false) {
            //console.log('An error occurred while creating the room: ' + data.message);
          } else {
            //setWebinarData(data); // Установка полученных данных
          }
          //history.go(0);
          
        } catch (error) {
          console.error('An error occurred while creating the room:', error);
        }
      } else {
        console.error('Name and urlroom validation failed.');
      }
    }
  
    //console.log('Значение поля 1:', inputValue1);
    //console.log('Значение поля 2:', inputValue2);
    setOpen(false);
  };


  
  useEffect(() => {
    const fetchData = async () => {
      const surl = 'rooms'; // значение для surl
      const { data, status, error } = await fetchUserData(surl);
      //setData(result);
          
      //console.log(status);
          //console.log('+++++++++++++++++++',response.status, showError403);
          if (status === 200) {
            // Continue with page loading
            setShowError403(false);
          } else if (status === 403) {
            // Display 403 error page
          
            setShowError403(true);
            
          } else if (status === 500) {
            // Display 500 error page
            setShowError500(true);
          } else {
            // Handle other status codes as needed
          }
      
      
    };

    fetchData();
  }, []);




  useEffect(() => {
    async function fetchData() {
      const userId = localStorage.getItem('UserId');
      const token = localStorage.getItem('Token');

      if (userId && token) {
        const url = `https://learninglive.ru:5000/api/rooms`;
        
        try {
          const response = await axios.get(url, {
            headers: {
              'authorization': `${token}`
            }
          });

          const data = response.data;

          if (data.success === false) {
            //console.log('An error occurred while retrieving project data: ' + data.message);
          } else {
            setWebinarData(data); // Set the retrieved webinar data
          }
        } catch (error) {
          console.error('An error occurred while fetching project data:', error);
        }
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    //console.log("Webinar data:", webinarData); // Log to check the webinar data
  }, [webinarData]);

  const handleButtonClick = (id) => {
    const fullURL = `${window.location.origin}/web?id=${id}`;
    navigator.clipboard.writeText(fullURL)
      .then(() => {
        // Копирование успешно
        console.log('Ссылка скопирована в буфер обмена:', fullURL);
      })
      .catch((error) => {
        console.error('Ошибка при копировании ссылки:', error);
      });
  };

  const handleButtonClickModer = (id) => {
    navigate(`/web_moder?id=${id}`);

  };

  const handleButtonClickLive = (id) => {
    navigate(`/web?id=${id}`);

  };

  const handleButtonClickSetup = (id) => {
    // Ваша логика обработки нажатия на кнопку
    

    // Ваша логика обработки нажатия на кнопку
   
      navigate(`/rooms/rooms_setup?id=${id}`);
    // Здесь можно вызвать любую другую логику или функции, связанные с нажатием кнопки
  
  };

  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <Button type="primary"  onClick={() => handleButtonClickSetup(record.uuid)}>Настройка комнаты</Button>
          <span style={{ marginRight: '15px' }} />
          <Button onClick={() => handleButtonClick(record.uuid)}>Ссылка для зрителей</Button>
          <span style={{ marginRight: '15px' }} />
          <Button onClick={() => handleButtonClickLive(record.uuid)}>Перейти в эфир</Button>
          <span style={{ marginRight: '15px' }} />
          <Button type="dashed" onClick={() => handleButtonClickModer(record.uuid)}>Модерация</Button>
        </div>
      ),
    },
    {
      title: 'Autor',
      dataIndex: 'autor',
      key: 'autor',
    },
    // Add more column definitions as needed
  ];



  return (
    <>
    {/* Условный рендеринг в зависимости от состояний ошибок */}
    {showError403 ? (
      <P403 />
    ) : showError500 ? (
      <P500 />
    ) : (

              <div>
                <div>
                <h1>Вебинарные комнаты</h1>
                <Button type="primary" onClick={showModal} >Добавить</Button>
                <Table dataSource={webinarData} columns={columns} rowKey="id" style={{ width: '900px' }} />
                </div>
                <div>
                <Modal
                  title="Создание новой комнаты"
                  open={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Input style={{ marginBottom: '20px' }}  placeholder="Укажите название комнаты" value={inputValue1} onChange={(e) => setInputValue1(e.target.value)} />
                  <Input placeholder="Укажите URL комнаты" value={inputValue2} onChange={(e) => setInputValue2(e.target.value)} />
                </Modal>

                </div>
              </div>
      )}
      </>
  );
};

export default WebinarRooms;
