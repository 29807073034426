import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Alert } from 'antd';

const { Title } = Typography;

const TgMain = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [form] = Form.useForm();

    // Инициализация данных пользователя
    const initializeData = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;
            const initDataRaw = tg.initData;  // Получаем начальные данные
            console.log('initDataRaw:', initDataRaw);

            if (initDataRaw) {
                const params = new URLSearchParams(initDataRaw);
                const user = JSON.parse(decodeURIComponent(params.get('user')));
                
                if (user) {
                    console.log('Полученные данные пользователя:', user);
                    setUserData(user);
                    form.setFieldsValue({
                        firstName: user.first_name,
                        lastName: user.last_name,
                        username: user.username,
                        userId: user.id,  // Добавление ID пользователя
                    });

                    // Настройка кнопки "Отправить"
                    tg.MainButton.setText("Отправить");
                    tg.MainButton.show();
                } else {
                    console.warn('Данные пользователя недоступны.');
                    setErrorMessage('Данные пользователя недоступны.');
                }
            } else {
                console.warn('initDataRaw недоступны.');
                setErrorMessage('initDataRaw недоступны.');
            }
        } else {
            console.warn('Telegram WebApp не доступен.');
            setErrorMessage('Telegram WebApp не доступен.');
        }

        setLoading(false);
    };

    useEffect(() => {
        initializeData();
    }, [form]);

    // Обработка отправки формы
    const onFinish = (values) => {
        console.log('Полученные данные формы:', values);
    };

    if (loading) {
        return <div>Загрузка...</div>;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Title level={2}>Данные пользователя Telegram</Title>
            {errorMessage && (
                <Alert
                    message="Ошибка"
                    description={errorMessage}
                    type="error"
                    showIcon
                    style={{ marginBottom: 24, width: 300 }}
                />
            )}
            <Form form={form} onFinish={onFinish} layout="vertical" style={{ width: 300 }}>
                <Form.Item label="Имя" name="firstName">
                    <Input readOnly />
                </Form.Item>
                <Form.Item label="Фамилия" name="lastName">
                    <Input readOnly />
                </Form.Item>
                <Form.Item label="Имя пользователя" name="username">
                    <Input readOnly />
                </Form.Item>
                <Form.Item label="ID пользователя" name="userId">
                    <Input readOnly />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Отправить заявку
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default TgMain;