
import React, { useState, useEffect } from 'react';
import { Table, Tabs, Tag, Spin, Alert, Button, Input, Space } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import P403 from '../authorization/403';
import P500 from '../authorization/500';
import fetchUserData from '../components/apiPrava';
import { useLocation } from 'react-router-dom';


const Analitics = () => {
    const [showError403, setShowError403] = useState(true);
    const [showError500, setShowError500] = useState(false);
    const [importHistory, setImportHistory] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    
    // Добавить в состояния компонента
    const [paymentsData, setPaymentsData] = useState([]);
    const [paymentsTableData, setPaymentsTableData] = useState([]);
    const [paymentsColumns, setPaymentsColumns] = useState([]);

    const [loading, setLoading] = useState({
        history: false,
        users: false,
        download: false,
        orders: false,
        downloadOrders: false,
        metrika: false, 
        pay: false,
    });
   // Обновите состояние error
    const [error, setError] = useState({
    history: null,
    users: null,
    download: null,
    orders: null,
    downloadOrders: null,
    metrika: null,
    metrikaVisits: null,
    metrikaViews: null,
    pay: null,
    });

    // Добавить в состояния
    const [result, setResult] = useState(null);
    //const [metrikaToken, setMetrikaToken] = useState(null);

    const [searchValue, setSearchValue] = useState('');
    const [data1Value, setdata1Value] = useState('');
    const [data2Value, setdata2Value] = useState('');

    // Состояния для заказов
    const [ordersData, setOrdersData] = useState([]);
    const [ordersTableData, setOrdersTableData] = useState([]);
    const [ordersColumns, setOrdersColumns] = useState([]);
    const [searchOrdersValue, setSearchOrdersValue] = useState('');
    const [searchPayValue, setSearchPayValue] = useState('');

    // Состояния для метрики
    const [metrikaData, setMetrikaData] = useState([]);
    const [metrikaTableData, setMetrikaTableData] = useState([]);
    const [metrikaColumns, setMetrikaColumns] = useState([]);
    const [searchMetrikaValue, setSearchMetrikaValue] = useState(''); // Было searchmetrikaValue
 
    const location = useLocation(); // Добавить в начале компонента

    const generateColumnsFromData = (data) => {
        if (!data || data.length === 0) return [];
        
        const sampleItem = data[0];
        return Object.keys(sampleItem).map((field, index) => ({
            title: field,
            dataIndex: field,
            key: `column_${index}`,
            ellipsis: true,
            render: (text) => text || '-',
            sorter: (a, b) => String(a[field]).localeCompare(String(b[field])),
            sortDirections: ['ascend', 'descend'],
        }));
    };

    const historyColumns = [
        {
            title: 'Дата и время',
            dataIndex: 'indate',
            key: 'indate',
            render: (text) => new Date(text).toLocaleString(),
            sorter: (a, b) => {
                const dateA = new Date(a.indate);
                const dateB = new Date(b.indate);
                return dateB - dateA; // Для сортировки по убыванию
            },
            defaultSortOrder: 'ascend',
        },
        { title: 'Тип импорта', dataIndex: 'tip', key: 'tip' },
        { title: 'Код API', dataIndex: 'id_gk_api', key: 'id_gk_api' },
        {
            title: 'Количество строк',
            dataIndex: 'kolvo',
            key: 'kolvo',
            render: (kolvo) => kolvo?.toLocaleString() || 0
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag color={status === 'completed' ? 'green' : status === 'error' ? 'red' : 'blue'}>
                    {status}
                </Tag>
            )
        },
        {
            title: 'Ошибка',
            dataIndex: 'error_details',
            key: 'error_details',
            render: (kolvo) => kolvo?.toLocaleString() || 0
        },
        {
            title: 'Даты',
            dataIndex: 'date_in',
            key: 'date_in',
            render: (kolvo) => kolvo?.toLocaleString() || 0
        },
    ];
        // Замените эти значения на свои!
        const [metrikaToken, setMetrikaToken] = useState(null);
        const [selectedCounter, setSelectedCounter] = useState(null);
        const [countersList, setCountersList] = useState([]);

        const CLIENT_ID = 'a0bcfcd7c8724d0ab575f5cc1871af62';
        const REDIRECT_URI = encodeURIComponent('https://dev.learninglive.ru:3000/analitics');

        const handleTokenMetrika = async () => {
            const scope = encodeURIComponent('metrika:read metrika:write');
            const authUrl = `https://oauth.yandex.ru/authorize?response_type=token&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${scope}`;
            window.location.href = authUrl;
          };

        const handleSelectCounter = async (counterId) => {
            try {
              const token = localStorage.getItem('Token');
              const schema = window.location.hostname.split(".")[0];
              console.log("FFFFFFFFFFFFFFFFFFFF",counterId, schema, token, "МЕТРИКА ТОКЕН", metrikaToken);
              await axios.post('https://learninglive.ru:5000/api/save-counter', {
                counter_id: counterId,
                schema: schema,
                tokenmetrika: metrikaToken              }, {
                headers: {
                    surl: 'getcourse',
                    schema: schema,
                  authorization: token
                }
              });
          
              setSelectedCounter(counterId);
              localStorage.setItem('selected_counter', counterId);
              
            } catch (error) {
              console.error('Ошибка сохранения счетчика:', error);
            }
          };
        
          const handleCreateVisitRequest = async () => {
            try {
              setLoading(prev => ({...prev, metrika: true}));
              setError(prev => ({...prev, metrika: null}));
          
              // Проверка токена
              const token = localStorage.getItem('Token');
              if (!token) {
                setError(prev => ({...prev, metrika: 'Требуется авторизация'}));
                return;
              }
          
              // Валидация дат
              if (!data1Value || !data2Value) {
                setError(prev => ({...prev, metrika: 'Укажите обе даты'}));
                return;
              }
          
              const response = await axios.post(
                'https://learninglive.ru:5000/api/check-export',
                { date1: data1Value, date2: data2Value },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    schema: window.location.hostname.split('.')[0],
                    'Content-Type': 'application/json'
                  }
                }
              );
          
              // Проверяем структуру ответа
              if (!response.data?.success) {
                throw new Error(response.data?.message || 'Неизвестная ошибка сервера');
              }
          
              // Если success: true, но есть сообщение об ошибке в данных
              if (response.data.success && !response.data.possible) {
                setError(prev => ({...prev, 
                  metrika: response.data.message || 'Экспорт невозможен'
                }));
                return;
              }
          
              // Успешный случай
              console.log('Успешный ответ:', response.data);
              setResult(response.data);
          
            } catch (error) {
              // Улучшенная обработка ошибок
              const errorMessage = error.response?.data?.message 
                || error.message 
                || 'Ошибка при выполнении запроса';
              
              setError(prev => ({...prev, 
                metrika: errorMessage
              }));
              
            } finally {
              setLoading(prev => ({...prev, metrika: false}));
            }
          };

          const loadImportHistory = async () => {
            try {
                setLoading(prev => ({...prev, history: true}));
                setError(prev => ({...prev, history: null}));
                
                const token = localStorage.getItem('Token');
                const schema = window.location.hostname.split(".")[0];
                
                const response = await axios.get('https://learninglive.ru:5000/api/gkimport', {
                    headers: {
                        authorization: token,
                        surl: 'getcourse',
                        schema: schema,
                    },
                });
        
                if (response.data && Array.isArray(response.data)) {
                    const formattedData = response.data
                        .map(item => ({
                            key: item.id,
                            ...item
                        }))
                        // Сортировка по убыванию даты
                        .sort((a, b) => new Date(b.indate) - new Date(a.indate));
                    
                    setImportHistory(formattedData);
                }
            } catch (err) {
                setError(prev => ({...prev, history: err.message}));
            } finally {
                setLoading(prev => ({...prev, history: false}));
            }
        };

    const handleDownloadPayments = async () => {
        try {
            setLoading(prev => ({...prev, pay: true}));
            setError(prev => ({...prev, pay: null}));
            setPaymentsTableData([]);
            setPaymentsColumns([]);
            
            const token = localStorage.getItem('Token');
            const schema = window.location.hostname.split(".")[0];
            
            const response = await axios.get('https://learninglive.ru:5000/api/gkpayments2', {
                headers: {
                    authorization: token,
                    surl: 'getcourse',
                    schema: schema,
                    idgk: searchPayValue,
                },
                params: { search: searchPayValue }
            });
    
            if (!response.data.success) {
                throw new Error(response.data.error_message || 'Ошибка при скачивании платежей');
            }
    
            const rawData = response.data.firstRecord || [];

         // Нормализация данных для таблицы
        const formattedData = rawData.map((item, index) => ({
            key: item.Номер || `payment_${index}`, // Убедитесь, что есть уникальный ключ
            ...Object.keys(item).reduce((acc, key) => {
                // Преобразование числовых полей
                const value = item[key];
                acc[key] = typeof value === 'number' 
                    ? Number(value.toFixed(2)) 
                    : value;
                return acc;
            }, {})
        }));
            
        // Генерация колонок с правильными типами данных
        const generatedColumns = generateColumnsFromData(formattedData).map(col => {
            if (col.title.toLowerCase().includes('сумма')) {
                return {
                    ...col,
                    render: (text) => text?.toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: 'RUB',
                        minimumFractionDigits: 2
                    }) || '-'
                };
            }
            return col;
        });
            
            setPaymentsColumns(generatedColumns);
            setPaymentsTableData(formattedData);
    
        } catch (err) {
            setError(prev => ({...prev, pay: err.message}));
        } finally {
            setLoading(prev => ({...prev, pay: false}));
        }
    };
    
    
    const handleCreatePaymentRequest = async () => {
        try {
            setLoading(prev => ({...prev, users: true}));
            setError(prev => ({...prev, users: null}));
            setUsersData([]);
            
            const token = localStorage.getItem('Token');
            const schema = window.location.hostname.split(".")[0];
            
            const response = await axios.get('https://learninglive.ru:5000/api/gkpayments1', {
                headers: {
                    authorization: token,
                    surl: 'getcourse',
                    schema: schema,
                    data1: data1Value,
                    data2: data2Value,
                },
            });

            if (response.data?.data) {
                const formattedData = response.data.data.map((user, index) => ({
                    key: user.id || index,
                    ...user
                }));
                setUsersData(formattedData);
            }
        } catch (err) {
            setError(prev => ({...prev, users: err.message}));
        } finally {
            setLoading(prev => ({...prev, users: false}));
        }
    };



    const loadUsersData = async () => {
        try {
            setLoading(prev => ({...prev, users: true}));
            setError(prev => ({...prev, users: null}));
            setUsersData([]);
            
            const token = localStorage.getItem('Token');
            const schema = window.location.hostname.split(".")[0];
            
            const response = await axios.get('https://learninglive.ru:5000/api/gkuser1', {
                headers: {
                    authorization: token,
                    surl: 'getcourse',
                    schema: schema,
                    data1: data1Value,
                    data2: data2Value,
                },
            });

            if (response.data?.data) {
                const formattedData = response.data.data.map((user, index) => ({
                    key: user.id || index,
                    ...user
                }));
                setUsersData(formattedData);
            }
        } catch (err) {
            setError(prev => ({...prev, users: err.message}));
        } finally {
            setLoading(prev => ({...prev, users: false}));
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const surl = 'web_moder';
                const { status } = await fetchUserData(surl);
                
                if (status === 200) {
                    setShowError403(false);
                    await loadImportHistory();
                } else if (status === 403) {
                    setShowError403(true);
                } else if (status === 500) {
                    setShowError500(true);
                }
            } catch (err) {
                console.error('Ошибка при проверке прав:', err);
            }
        };
        fetchInitialData();
    }, [location]);


    useEffect(() => {
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const accessToken = params.get('access_token');
      
        const fetchCounters = async (token) => {
          try {
            const response = await axios.get('https://api-metrika.yandex.net/management/v1/counters', {
              headers: {
                Authorization: `OAuth ${token}`
              }
            });
            
            setCountersList(response.data.counters);
            localStorage.setItem('yandex_metrika_token', token);
            setMetrikaToken(token);
            
          } catch (error) {
            console.error('Ошибка получения счетчиков:', error);
            setError(prev => ({...prev, 
            metrika: 'Ошибка получения списка счетчиков: ' + error.message 
            }));
          }
        };
      
        if (accessToken) {
          window.history.pushState({}, document.title, window.location.pathname);
          fetchCounters(accessToken);
        }
      }, [location]);



    const handleCreateRequest = () => loadUsersData();

    const handleCreateOrderRequest = async () => {
        try {
            setLoading(prev => ({...prev, orders: true}));
            setError(prev => ({...prev, orders: null}));
            
            const token = localStorage.getItem('Token');
            const schema = window.location.hostname.split(".")[0];
            
            const response = await axios.get('https://learninglive.ru:5000/api/gkdeal1', {
                headers: {
                    authorization: token,
                    surl: 'getcourse',
                    schema: schema,
                    data1: data1Value,
                    data2: data2Value,                    
                },
            });
    
            if (response.data?.data) {
                const formattedData = response.data.data.map((order, index) => ({
                    key: order.id || index,
                    ...order
                }));
                setOrdersData(formattedData);
            }
        } catch (err) {
            setError(prev => ({...prev, orders: err.message}));
        } finally {
            setLoading(prev => ({...prev, orders: false}));
        }
    };
    
    const handleDownloadOrders = async () => {
        try {
            setLoading(prev => ({...prev, downloadOrders: true}));
            setError(prev => ({...prev, downloadOrders: null}));
            setOrdersTableData([]);
            setOrdersColumns([]);
            
            const token = localStorage.getItem('Token');
            const schema = window.location.hostname.split(".")[0];
            
            const response = await axios.get('https://learninglive.ru:5000/api/gkdeal2', {
                headers: {
                    authorization: token,
                    surl: 'getcourse',
                    schema: schema,
                    idgk: searchOrdersValue,
                },
                params: { search: searchOrdersValue }
            });
    
            if (!response.data.success) {
                throw new Error(response.data.error_message || 'Ошибка при скачивании заказов');
            }
    
            const rawData = response.data.data || [];
            const formattedData = rawData.map((item, index) => ({
                key: item.id || index,
                ...item
            }));
            
            const generatedColumns = generateColumnsFromData(formattedData);
            
            setOrdersColumns(generatedColumns);
            setOrdersTableData(formattedData);
    
        } catch (err) {
            setError(prev => ({...prev, downloadOrders: err.message}));
        } finally {
            setLoading(prev => ({...prev, downloadOrders: false}));
        }
    };



    const formatDateInput = (value) => {
        // Удаляем все нецифровые символы
        let cleaned = value.replace(/\D/g, '');
        
        // Ограничиваем длину и добавляем разделители
        if (cleaned.length > 8) cleaned = cleaned.slice(0, 8);
        let formatted = '';
        
        for (let i = 0; i < cleaned.length; i++) {
          if (i === 4 || i === 6) formatted += '-';
          formatted += cleaned[i];
        }
        
        return formatted;
      };


    const handleDownloadTemplate = async () => {
        try {
            setLoading(prev => ({...prev, download: true}));
            setError(prev => ({...prev, download: null}));
            setTableData([]);
            setColumns([]);
            
            const token = localStorage.getItem('Token');
            const schema = window.location.hostname.split(".")[0];
            
            const response = await axios.get('https://learninglive.ru:5000/api/gkuser2', {
                headers: {
                    authorization: token,
                    surl: 'getcourse',
                    schema: schema,
                    idgk: searchValue,
                },
                params: { search: searchValue }
            });

            if (!response.data.success) {
                throw new Error(response.data.error_message || 'Ошибка при скачивании');
            }

            const rawData = response.data.data || [];
            const formattedData = rawData.map((item, index) => ({
                key: item.id || index,
                ...item
            }));
            
            const generatedColumns = generateColumnsFromData(formattedData);
            
            setColumns(generatedColumns);
            setTableData(formattedData);

        } catch (err) {
            setError(prev => ({...prev, download: err.message}));
        } finally {
            setLoading(prev => ({...prev, download: false}));
        }
    };

    const tabItems = [
        {
            key: '1',
            label: 'Импорт пользователей',
            children: (
                <div style={{ padding: '20px' }}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div style={{ padding: '10px', background: '#ffe', marginBottom: '20px' }}>
                            <h4>Отладочная информация:</h4>
                            <pre>
                                Параметр поиска: {searchValue}<br/>
                                Колонок: {columns.length}<br/>
                                Строк: {tableData.length}<br/>
                                Ошибка: {error.download || 'нет'}
                            </pre>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <Button 
                                type="primary" 
                                icon={<PlusOutlined />} 
                                onClick={handleCreateRequest}
                                style={{ marginRight: '10px' }}
                                loading={loading.users}
                            >
                                Создать запрос
                            </Button>
                            <Input 
                                placeholder="С ... (YYYY-MM-DD)"
                                value={data1Value}
                                onChange={(e) => {
                                    const formatted = formatDateInput(e.target.value);
                                    setdata1Value(formatted);
                                }}
                                style={{ width: 300, marginRight: '10px' }}
                                maxLength={10}
                                />
                                <Input 
                                placeholder="ПО ... (YYYY-MM-DD)"
                                value={data2Value}
                                onChange={(e) => {
                                    const formatted = formatDateInput(e.target.value);
                                    setdata2Value(formatted);
                                }}
                                style={{ width: 300, marginRight: '10px' }}
                                maxLength={10}
                            />

                            <Button 
                               type="primary" 
                               icon={<PlusOutlined />} 
                                onClick={handleDownloadTemplate}
                                style={{ marginRight: '10px' }}
                                loading={loading.download}
                            >
                                Скачать таблицу
                            </Button>
                            <Input 
                                placeholder="ID..."
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                style={{ width: 300 }}
                            />
                        </div>
                        {error.download && (
                            <Alert
                                message="Ошибка скачивания"
                                description={error.download}
                                type="error"
                                showIcon
                                style={{ marginBottom: 20 }}
                            />
                        )}
                        <Spin spinning={loading.users || loading.download}>
                            {error.users && (
                                <Alert
                                    message="Ошибка загрузки"
                                    description={error.users}
                                    type="error"
                                    showIcon
                                    style={{ marginBottom: 20 }}
                                />
                            )}
                            <Table
                                columns={columns.length ? columns : generateColumnsFromData(usersData)}
                                dataSource={tableData.length ? tableData : usersData}
                                pagination={{ pageSize: 10, showSizeChanger: true }}
                                bordered
                                scroll={{ x: 'max-content' }}
                                locale={{
                                    emptyText: error.users || error.download 
                                        ? 'Ошибка загрузки данных' 
                                        : 'Нет данных'
                                }}
                            />
                        </Spin>
                    </Space>
                </div>
            ),
        },
        {
            key: '2',
            label: 'Импорт заказов',
            children: (
                <div style={{ padding: '20px' }}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div style={{ padding: '10px', background: '#ffe', marginBottom: '20px' }}>
                            <h4>Отладочная информация (Заказы):</h4>
                            <pre>
                                Параметр поиска: {searchOrdersValue}<br/>
                                Колонок: {ordersColumns.length}<br/>
                                Строк: {ordersTableData.length}<br/>
                                Ошибка: {error.orders || 'нет'}
                            </pre>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <Button 
                                type="primary" 
                                icon={<PlusOutlined />} 
                                onClick={handleCreateOrderRequest}
                                style={{ marginRight: '10px' }}
                                loading={loading.orders}
                            >
                                Создать запрос заказов
                            </Button>
                            <Input 
                                placeholder="С ... (YYYY-MM-DD)"
                                value={data1Value}
                                onChange={(e) => {
                                    const formatted = formatDateInput(e.target.value);
                                    setdata1Value(formatted);
                                }}
                                style={{ width: 300, marginRight: '10px' }}
                                maxLength={10}
                                />
                                <Input 
                                placeholder="ПО ... (YYYY-MM-DD)"
                                value={data2Value}
                                onChange={(e) => {
                                    const formatted = formatDateInput(e.target.value);
                                    setdata2Value(formatted);
                                }}
                                style={{ width: 300, marginRight: '10px' }}
                                maxLength={10}
                            />


                            <Button 
                                icon={<DownloadOutlined />} 
                                onClick={handleDownloadOrders}
                                style={{ marginRight: '10px' }}
                                loading={loading.downloadOrders}
                            >
                                Скачать заказы
                            </Button>
                            <Input 
                                placeholder="ID заказа..."
                                value={searchOrdersValue}
                                onChange={(e) => setSearchOrdersValue(e.target.value)}
                                style={{ width: 300 }}
                            />
                        </div>
                        {error.downloadOrders && (
                            <Alert
                                message="Ошибка скачивания заказов"
                                description={error.downloadOrders}
                                type="error"
                                showIcon
                                style={{ marginBottom: 20 }}
                            />
                        )}
                        <Spin spinning={loading.orders || loading.downloadOrders}>
                            {error.orders && (
                                <Alert
                                    message="Ошибка загрузки заказов"
                                    description={error.orders}
                                    type="error"
                                    showIcon
                                    style={{ marginBottom: 20 }}
                                />
                            )}
                            <Table
                                columns={ordersColumns.length ? ordersColumns : generateColumnsFromData(ordersData)}
                                dataSource={ordersTableData.length ? ordersTableData : ordersData}
                                pagination={{ pageSize: 10, showSizeChanger: true }}
                                bordered
                                scroll={{ x: 'max-content' }}
                                locale={{
                                    emptyText: error.orders || error.downloadOrders 
                                        ? 'Ошибка загрузки данных' 
                                        : 'Нет данных по заказам'
                                }}
                            />
                        </Spin>
                    </Space>
                </div>
            ),
        },
        {
            key: '3',
            label: 'Импорт платежей',
            children: (
                <div style={{ padding: '20px' }}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div style={{ padding: '10px', background: '#ffe', marginBottom: '20px' }}>
                            <h4>Отладочная информация (Платежи):</h4>
                            <pre>
                                Параметр поиска: {searchPayValue}<br/>
                                Колонок: {paymentsColumns.length}<br/>
                                Строк: {paymentsTableData.length}<br/>
                                Ошибка: {error.pay || 'нет'}
                            </pre>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <Input 
                                placeholder="С ... (YYYY-MM-DD)"
                                value={data1Value}
                                onChange={(e) => {
                                    const formatted = formatDateInput(e.target.value);
                                    setdata1Value(formatted);
                                }}
                                style={{ width: 300, marginRight: '10px' }}
                                maxLength={10}
                            />
                            <Input 
                                placeholder="ПО ... (YYYY-MM-DD)"
                                value={data2Value}
                                onChange={(e) => {
                                    const formatted = formatDateInput(e.target.value);
                                    setdata2Value(formatted);
                                }}
                                style={{ width: 300, marginRight: '10px' }}
                                maxLength={10}
                            />
                            <Button 
                                type="primary" 
                                icon={<PlusOutlined />} 
                                onClick={handleCreatePaymentRequest}
                                style={{ marginRight: '10px' }}
                                loading={loading.pay}
                            >
                                Получить запрос
                            </Button>
                            <Button 
                                type="primary" 
                                icon={<DownloadOutlined />} 
                                onClick={handleDownloadPayments}
                                style={{ marginRight: '10px' }}
                                loading={loading.pay}
                            >
                                Скачать платежи
                            </Button>
                            <Button 
                                type="primary" 
                                icon={<PlusOutlined />} 
                                //onClick={handleImportPayments}
                                style={{ marginRight: '10px' }}
                                loading={loading.pay}
                            >
                                Загрузить данные
                            </Button>
                            <Input 
                                placeholder="ID платежа..."
                                value={searchPayValue}
                                onChange={(e) => setSearchPayValue(e.target.value)}
                                style={{ width: 300 }}
                            />
                        </div>
                        {error.pay && (
                            <Alert
                                message="Ошибка"
                                description={error.pay}
                                type="error"
                                showIcon
                                style={{ marginBottom: 20 }}
                            />
                        )}
                        <Spin spinning={loading.pay}>
                        <Table
                            columns={paymentsColumns}
                            dataSource={paymentsTableData}
                            pagination={{ pageSize: 10, showSizeChanger: true }}
                            bordered
                            scroll={{ x: 'max-content' }}
                            locale={{
                                emptyText: error.pay 
                                    ? 'Ошибка загрузки данных' 
                                    : 'Нет данных по платежам'
                            }}
                        />
                        </Spin>
                    </Space>
                </div>
            ),
        },
        {
            key: '4',
            label: 'Импорт групп',
            children: (<div>Контент для импорта групп</div>

            ),
        },
        {
            key: '5',
            label: 'Импорт Яндекс Метрики',
            children: ( 
                            <div style={{ marginBottom: '20px' }}>
                            <div style={{ padding: '10px', background: '#ffe', marginBottom: '20px' }}>
                            <h4>Отладочная информация (Метрика):</h4>
                            <pre>
                                Параметр поиска: {searchMetrikaValue}<br/>
                                Колонок: {metrikaColumns.length}<br/>
                                Строк: {metrikaTableData.length}<br/>
                                Ошибка: {error.metrika || 'нет'}
                            </pre>
                        <div>
                                
                                <div>
                                    <h4>Выберите счетчик:</h4>
                                    {countersList.map(counter => (
                                    <Button 
                                        key={counter.id} 
                                        onClick={handleSelectCounter}
                                        style={{ margin: '5px' }}
                                    >
                                        {counter.name} (ID: {counter.id})
                                    </Button>
                                    ))}
                                </div>
                                

                                {selectedCounter && (
                                <Alert 
                                    message={`Выбран счетчик: ${selectedCounter}`} 
                                    type="success" 
                                    showIcon
                                />
                                )}

                        </div>
                        </div>


                        {result && (
                            <div>
                            <h4>Результат проверки:</h4>
                            <pre>{JSON.stringify(result, null, 2)}</pre>
                            </div>
                        )}

                        {error && (
                            <Alert
                            message="Ошибка"
                            description={error.error}
                            type="error"
                            showIcon
                            />
                        )}


                            <Input 
                                placeholder="С ... (YYYY-MM-DD)"
                                value={data1Value}
                                onChange={(e) => {
                                    const formatted = formatDateInput(e.target.value);
                                    setdata1Value(formatted);
                                }}
                                style={{ width: 300, marginRight: '10px' }}
                                maxLength={10}
                                />
                                <Input 
                                placeholder="ПО ... (YYYY-MM-DD)"
                                value={data2Value}
                                onChange={(e) => {
                                    const formatted = formatDateInput(e.target.value);
                                    setdata2Value(formatted);
                                }}
                                style={{ width: 300, marginRight: '10px' }}
                                maxLength={10}
                            />
                            <Button 
                                type="primary" 
                                icon={<PlusOutlined />} 
                                onClick={handleTokenMetrika}
                                style={{ marginRight: '10px' }}
                                loading={loading.orders}
                            >
                                Получить токен
                            </Button>
                            <Button 
                                type="primary" 
                                icon={<PlusOutlined />} 
                                onClick={handleCreateVisitRequest}
                                style={{ marginRight: '10px' }}
                                loading={loading.orders}
                            >
                                Создать запрос проверки 
                            </Button>
                            
                            <Button 
                            type="primary" 
                            icon={<PlusOutlined />} 
                            //onClick={handleCreateProsvotrRequest}
                            style={{ marginRight: '10px' }}
                            loading={loading.orders}
                            >
                                Создать запрос просомтров
                            </Button>
                            </div>
        ),

        },

        {
            key: '6',
            label: 'История импорта',
            children: (
                <Spin spinning={loading.history}>
                    {error.history && (
                        <Alert
                            message="Ошибка"
                            description={error.history}
                            type="error"
                            showIcon
                            style={{ marginBottom: 20 }}
                        />
                    )}
                    <Table
                        columns={historyColumns}
                        dataSource={importHistory}
                        pagination={{ pageSize: 10 }}
                        bordered
                        locale={{ emptyText: 'Нет данных' }}
                    />
                </Spin>
            ),
        },
    ];

    return (
        <>
            {showError403 ? <P403 /> : 
             showError500 ? <P500 /> : (
                <div style={{ padding: '24px' }}>
                    <h2>Работа с аналитикой геткурса</h2>
                    <script src="https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js"></script>
                    <script src="https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js"></script>
                    <Tabs
                        defaultActiveKey="1"
                        items={tabItems}
                        tabPosition="top"
                        size="large"
                        style={{ marginTop: '20px' }}
                    />
                </div>
            )}
        </>
    );
};

export default Analitics;
