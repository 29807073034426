import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Input } from 'antd';
import {  Link, useNavigate  } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import VideoPlayer from './Webvideo';
import WebChat from './Webchat_moder';
import Websales from './Websales';
import axios from 'axios'; // Don't forget to import axios
import { ThemesContext } from 'react-admin';
import P403 from '../authorization/403';
import P500 from '../authorization/500';
import fetchUserData from '../components/apiPrava'; // Импорт функции из созданного модуля
import './Web_moder.css';



const WebinarModer = () => {
    const [showError403, setShowError403] = useState(true);
    const [showError500, setShowError500] = useState(false);
    const [data, setData] = useState([]); // Состояние для данных

    useEffect(() => {
        const fetchData = async () => {
          const surl = 'web_moder'; // значение для surl
          const { data, status, error } = await fetchUserData(surl);
          //setData(result);
              
          //console.log(status);
              //console.log('+++++++++++++++++++',response.status, showError403);
              if (status === 200) {
                // Continue with page loading
                setShowError403(false);
              } else if (status === 403) {
                // Display 403 error page
              
                setShowError403(true);
                
              } else if (status === 500) {
                // Display 500 error page
                setShowError500(true);
              } else {
                // Handle other status codes as needed
              }
          
          
        };
    
        fetchData();
      }, []);

      useEffect(() => {
        //


      }, []); 


    return(
    <>
    {/* Условный рендеринг в зависимости от состояний ошибок */}
    {showError403 ? (
      <P403 />
    ) : showError500 ? (
      <P500 />
    ) : (
       
        <div className='moder_screen' style={{ border: '1px solid red' }}>
        {/* общий блок */}    
            
            <div className='moder_videobuttonreport' style={{ border: '1px solid black' }}>
                {/* для видео + управление + низ */}
                <div className='moder_videobutton' style={{ border: '1px solid black' }}>
                {/** для блока видео + управление */}
                    <div style={{ border: '1px solid black' }}>
                        {/* video  */}
                        <VideoPlayer />
                    </div>
                    <div style={{ border: '1px solid black' }}>
                        {/* управление кнопками */}
                        <label> Управление </label>
                    </div>
                </div>
                <div style={{ border: '1px solid green' }}>
                    {/* низ для отчетов и управления */}
                    <h1>Отчеты</h1>
                </div>
            </div>
            <div className='chatflexmoder' style={{  }}>
                {/* чат */}
                <WebChat />
            </div>
            


        {/* общий блок*/}    
        </div>
    
    
    )}
    </>
    );

};


export default WebinarModer;