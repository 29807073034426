import React, {useState, useEffect} from 'react';
import { Form, Input, Button, Typography, Checkbox, Row, Col } from 'antd';
//import 'antd/dist/antd.css';
import VideoPlayer from './Webvideo';
import WebNav from './Webnav';
import WebChat from './Webchat';
import Websales from './Websales';
import './ChatContainer.css'; 
import './VideoPlayer.css';
import './Webinars.css';
import P403 from '../authorization/403';
import P500 from '../authorization/500';
import { Flex } from "antd";


const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

//const SubdomainContext = React.createContext(null);

const CenteredForm = () => {
  const [showError403, setShowError403] = useState(false);
  const [showError500, setShowError500] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [authCheckComplete, setAuthCheckComplete] = useState(false);
  const [id, setId] = useState(''); // Определение и инициализация переменной id
  const [data, setData] = useState(null); // Добавляем состояние для данных
  
  useEffect(() => {
// проверяем есть ли такая комната и получаем параметры
const fetchData = async () => {
  const token = localStorage.getItem('Token');
  //console.log(token);
  if (token) {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const idr = urlParams.get('id');
      const response = await fetch(`https://learninglive.ru:5000/api/room/${idr}`, {
        headers: {
          authorization: token,
        },
      });
      const result = await response.json();
      console.log(response.status);
      //const data = response.data;
      if (response.status === 200) {
        setData(result); // Передаем весь объект JSON непосредственно в setFormData
      } else {
        setShowError500(true);
        console.error('An error occurred while retrieving project data: ' + data.message);
      }
                //console.log('+++++++++++++++++++',response.status, showError403);
      
    } catch (error) {
      setShowError500(true);
      console.error('Error fetching user data:', error);
      // Handle error state as needed
    }
  } else {
    console.error('No token found in local storage');
    // Handle token absence as needed
  }
};

fetchData();


  }, []);



  useEffect(() => {
    // Парсинг параметров из URL
    const searchParams = new URLSearchParams(window.location.search);
    const idParam = searchParams.get('id');
    setId(idParam); // Устанавливаем переменную id из URL в состояние

    
    const subdomain = window.location.hostname.split('.')[0];
  

    //parseSubdomain(); // Вызов функции для извлечения поддомена

    //console.log('УРАААААААЫ', idParam , subdomain);

    

    // Check the persistent storage for the authorization state
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn === 'true') {
      setIsAuthorized(true);
    }
    setAuthCheckComplete(true); // Устанавливаем флаг, что проверка авторизации завершена
  }, []);


  const onFinish = (values) => {
    console.log('Received values:', values);
    setIsAuthorized(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  //const videoId = '4_HJx9ujkr4';

  const chatParams = {
    // Параметры для чата
    username: 'User123',
    theme: 'light',
  };

  const videoParams = {
    // Параметры для видеоплеера
    autoplay: true,
    quality: 'high',
  };

  const navParams = {
    // Параметры для видеоплеера
    autoplay: true,
    quality: 'high',
  };
   
  const salesParams = {
    // Параметры для видеоплеера
    autoplay: true,
    quality: 'high',
  };


  const videowidthStyle = {
    width: '100%', /* Занимает всю доступную ширину */
    height: '100%',
    margin: '0 auto', /* Центрирование по горизонтали */
 };

   
 if (authCheckComplete && isAuthorized) {
  return (
    <>
      {showError403 || showError500 ? (
        showError403 ? <P403 /> : <P500 />
      ) : (
        <div className="web">
          <WebNav navParams={navParams} />
          <div className="screenflex" style={{ border: '1px solid black' }}>
                  <div className="videosalesflex" style={{ border: '1px solid purple' }}>
                            <div className="videoflex" style={{ border: '1px solid blue' }}>            
                                      
                                        <VideoPlayer videoParams={videoParams} /> 
                                        
                            </div>
                            <div className="salesflex"  style={{ border: '1px solid green' }}>          
                                      
                                      <Websales />
                                      
                            </div>
                    </div>

                {/* чат идет отдельно */}
                <div className="chatflex"  style={{ border: '1px solid red' }}>
                          
                            <WebChat chatParams={chatParams} />
                          
                </div>


          </div>
        </div>
      )}
    </>
  );
} else if (authCheckComplete && !isAuthorized) {
  return (
    <>
      {showError403 || showError500 ? (
        showError403 ? <P403 /> : <P500 />
      ) : (
        <Row justify="center" style={{ minHeight: '100vh' }}>
          <Col span={8}>
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Title level={3}>Название вебинара</Title>
              <Text level={5}>Ведущие вебинара: Иван Иванов, Мария Петрова</Text>
              <Text level={5}>Дата проведения: 12 марта 2022</Text>
              <Form.Item
                label="Имя"
                name="name"
                rules={[{ required: true, message: 'Пожалуйста, введите ваше имя!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Войти в комнату
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
}
};

export default CenteredForm;