import React, { useState, useEffect } from 'react';

const p500 = () => {


    return (

        <>Внутренняя ошибка сервера</>
    );
};


export default p500