import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LoginPage.css';
import { Button, Modal, Space, Checkbox, Alert, Input, Form, Radio, Tag } from 'antd'; // Import Ant Design components
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import 'antd/dist/reset.css';


const { TabPane } = Tabs;


axios.defaults.timeout = 10000;

function Authorization({ onLogin }) {
  const [activeTab, setActiveTab] = useState('login');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [size, setSize] = useState('large');

  useEffect(() => {
    const token = localStorage.getItem('Token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []); // Пустой массив зависимостей гарантирует выполнение только один раз после монтирования компонента




  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };


  const handleLogin = async () => {
    try {
      setLoading(true);
      const userAppURL = window.location.hostname;
      const subdomain = userAppURL.split('.')[0] || 'public';
      const response = await axios.post(
        'https://learninglive.ru:5000/auth',
        { email, password, account: subdomain },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);

      if (response.data && response.data.token) {
        const token = response.data.token;
        const userid = response.data.userid;
        const first_name = response.data.first_name;
        const last_name = response.data.last_name;
        const email = response.data.email;
        const phone = response.data.phone;
        const key = 'your_secret_key';
        //console.log('**********---**************', first_name , last_name);
        localStorage.setItem('Token', token);
        localStorage.setItem('UserId', userid);
        localStorage.setItem('webname', `${first_name} ${last_name}`);
        localStorage.setItem('lastemail', email);
        localStorage.setItem('lastphone', phone);
        localStorage.setItem('isLoggedIn', 'true');
        setIsLoggedIn(true);
        onLogin(true);
        console.log('User authorized');
        navigate('/');
      } else {
        localStorage.setItem('isLoggedIn', 'false');
        setIsLoggedIn(false);
        onLogin(false);
        console.log('Authorization failed');
      }
    } catch (error) {
      console.error('Error during authorization:', error.response ? error.response.error : error.message);
    } finally {
      setLoading(false);
    }
  };

  
  
  
  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      setLoading(true); // Устанавливаем состояние загрузки при отправке запроса
      //Успешная регистрация
      const userAppURL = window.location.hostname;
      const subdomain = userAppURL.split('.')[0] || '';
  
      const requestData = {
        account: subdomain,
        email: email,
        username: username,
        password: password
      };
  
      const response = await axios.put('https://learninglive.ru:5000/register', 
      { email, password, username, account: subdomain }, 
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      console.log('User registered successfully:', response.data);
      // Все успешно
      setIsRegistered(true);


    } catch (error) {
      // Обработка ошибок
      console.log('Error during registration:', error);
    } finally {
      setLoading(false); // Сбрасываем состояние загрузки после получения ответа
    }
  };




  const handleRecovery = async (event) => {/* Оставшаяся логика восстановления*/};

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleLoginButtonClick = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const handleRegisterButtonClick = (e) => {
    e.preventDefault();
    handleRegister(e);
  };

  return (
 

  <div className="autorization">
    

   <div className="container" >

      

    <Tabs defaultActiveKey="1" type="card" size={size} style={{ border: '1px solid #000', borderRadius: '5px', padding: '20px' }} >
      <TabPane tab="Авторизация" key="1" >
      <   form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            <Input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <Input.Password placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Button type="primary" onClick={handleLoginButtonClick} disabled={!validateEmail(email)}>
              Войти
            </Button>
          </form>
      </TabPane>
      <TabPane tab="Регистрация" key="2">
      <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {isRegistered && (
              <Alert message="Вы успешно зарегистрированы! Авторизуйтесь!" type="warning" />
            )}
            <Input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <Input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <Input.Password placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Button type="primary" onClick={(e) => { e.preventDefault(); handleRegister(e); }}>
              Зарегистрироваться
            </Button>
          </form>
      </TabPane>
      <TabPane tab="Забыл пароль?" key="3">
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            <Input type="text" placeholder="Email" />
            <Button type="primary" onClick={handleRecovery}>Отправить новый</Button>
          </form>
      </TabPane>
    </Tabs>
  </div>


    </div>   
  );
  }
  
  export default Authorization;
  