import React, { useState, useEffect } from 'react';
import P403 from '../authorization/403';
import P500 from '../authorization/500';
import { Button, Flex } from 'antd';
import { Table, Form, Input, Modal, Radio } from "antd";



const dataSource = [
  {
    key: '1',
    name: 'Mike 666',
    name_getcourse: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    name_getcourse: '10 Downing Street',
  },
];


const columns = [
  {
    title: 'Id группы',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Название на getcourse',
    dataIndex: 'name_getcourse',
    key: 'name_getcourse',
  },
  {
    title: 'Дата старта',
    dataIndex: 'startdate',
    key: 'startdate',
  },
  {
    title: 'Дата окончания',
    dataIndex: 'enddate',
    key: 'enddate',
  },
  {
    title: 'Редактировать',
    dataIndex: 'red',
    key: 'red',
    render: () => <Button type="primary">Редактировать</Button>,
  },
];



const Met_group = () => {
    const [showError403, setShowError403] = useState(false);
    const [showError500, setShowError500] = useState(false);
    const [data, setData] = useState([]); // Состояние для данных

    useEffect(() => {
      const fetchUserData = async () => {
        const token = localStorage.getItem('Token');
        //console.log(token);
        if (token) {
          try {
            
            const response = await fetch('https://learninglive.ru:5000/api/prava', {
              headers: {
                authorization: token,
                surl: 'met_group',
              },
            });
            const result = await response.json();
            setData(result);

            //console.log('+++++++++++++++++++',response.status, showError403);
            if (response.status === 200) {
              // Continue with page loading
            } else if (response.status === 403) {
              // Display 403 error page
            
              setShowError403(true);
              
            } else if (response.status === 500) {
              // Display 500 error page
              setShowError500(true);
            } else {
              // Handle other status codes as needed
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error state as needed
          }
        } else {
          console.error('No token found in local storage');
          // Handle token absence as needed
        }
      };
  
      fetchUserData();
    }, []);
  
    return (
      <>
        {/* Условный рендеринг в зависимости от состояний ошибок */}
        {showError403 ? (
          <P403 />
        ) : showError500 ? (
          <P500 />
        ) : (
          // Тут ставим нормальное содержимое
          <div>
              <div>
              <Flex gap="large" wrap="wrap">
                 <Button type="primary">Добавить группу</Button>
              </Flex>
              </div>
              <div>
              <Table dataSource={dataSource} columns={columns} />;
              </div>
              </div>    
          // тут конец нормальности
        )}
      </>
    );
  };
  

export default Met_group