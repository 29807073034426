import React, { useState, useEffect } from 'react';

const p403 = () => {


    return (

        <>Доступ запрещен</>
    );
};


export default p403