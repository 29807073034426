import React, { useEffect, useRef, useState } from 'react';
import { Input, Form, Switch, Image, Table, Button } from 'antd';
//import './VideoPlayer.css';

const Websales = (props) => {

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            
            <div>
                {/* Выводим кнопки */}
                <div>
                    <p></p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                <Button type="primary"> Кнопка 1 </Button>
                <Button type="primary"> Кнопка 2 </Button>
                </div>
            </div>
            <div>
                {/* Выводим банер */}
                <div>
                    <p></p>
                </div>
                <Image
                height={100}
                src="https://avatars.mds.yandex.net/get-mpic/5323357/img_id6972637756536730956.jpeg/orig"
                                  />
            </div>

            <div>
                {/* Выводим HTML */}
                <div>
                    <p></p>
                </div>
                <>Тут код</>
            </div>





        </div>
        


    )

};
    export default Websales;