import React, { useEffect, useState } from 'react';

const StreamToNodeMediaServer = () => {
  const [isStreaming, setIsStreaming] = useState(false);
  let mediaRecorder;
  let ws;

  const startStreamingMedia = () => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(function(stream) {
        console.log('Медиапоток получен');
        const videoElement = document.getElementById('video');
        videoElement.srcObject = stream;
        
        ws = new WebSocket('wss://learninglive.ru/live');
        ws.onopen = function(event) {
          console.log('Соединение установлено');
        };
        
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = function(event) {
          if (event.data.size > 0) {
            ws.send(event.data);
          }
        };
        mediaRecorder.start();
        setIsStreaming(true);
      })
      .catch(function(error) {
        console.error('Ошибка при получении медиапотока:', error);
      });
  };

  const stopStreamingMedia = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    if (ws) {
      ws.close();
    }
    setIsStreaming(false);
  };

  useEffect(() => {
    return () => {
      stopStreamingMedia();
    };
  }, []);

  return (
    <div>
      <video id="video" width="640" height="480" autoPlay muted />
      {isStreaming ? (
        <button onClick={stopStreamingMedia}>Остановить трансляцию</button>
      ) : (
        <button onClick={startStreamingMedia}>Запустить трансляцию</button>
      )}
    </div>
  );
};

export default StreamToNodeMediaServer;