import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Input, Typography } from 'antd';



const Setting = () => {
  const [formData, setFormData] = useState({
    getcourse_api: '',
    getcourse_url_work: '',
    getcourse_url_api: '',
    name_full: '',
    i2crm: '',
    api: '',
    // другие поля
  });

  useEffect(() => {
    async function fetchData() {
      const userId = localStorage.getItem('UserId');
      const token = localStorage.getItem('Token');
      if (userId && token) {
        const url = `https://learninglive.ru:5000/project/${userId}`;  
        try {
          const response = await axios.get(url, {
            headers: {
              'authorization': `${token}`
            }
          });
          const data = response.data;
          if (data.success) {
            setFormData(data.data);
          } else {
            console.error('An error occurred while retrieving project data: ' + data.message);
          }
        } catch (error) {
          console.error('An error occurred while fetching project data:', error);
        }
      }
    }
    fetchData();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = async () => {
    try {
      const userId = localStorage.getItem('UserId');
      const token = localStorage.getItem('Token');
      const response = await axios.put(`https://learninglive.ru:5000/project/${userId}`, formData, {
        headers: {
          'Authorization': `${token}`
        }
      });
      console.log(response.data); // Logging the server response
    } catch (error) {
      console.error('An error occurred while saving project data:', error);
    }
  };

  return (
    <div>
      <div>
        <label htmlFor="getcourse_api">Getcourse API:</label>
        <Input type="text" id="getcourse_api" name="getcourse_api" value={formData.getcourse_api} onChange={handleChange}  />
      </div>
      <div>
        <label htmlFor="getcourse_url_work">Getcourse URL Work:</label>
        <Input type="text" id="getcourse_url_work" name="getcourse_url_work" value={formData.getcourse_url_work} onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="getcourse_url_api">Getcourse URL API:</label>
        <Input type="text" id="getcourse_url_api" name="getcourse_url_api" value={formData.getcourse_url_api} onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="name_full">Full Name:</label>
        <Input type="text" id="name_full" name="name_full" value={formData.name_full} onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="api">API:</label>
        <Typography.Text id="api" name="api"> {formData.api} </Typography.Text>
      </div>
      <div>
        <label htmlFor="i2crm">I2CRM:</label>
        <Input type="text" id="i2crm" name="i2crm" value={formData.i2crm} onChange={handleChange} />
      </div>
      <Button onClick={handleSave} type="primary" >СОХРАНИТЬ</Button>
    </div>
  );
};

export default Setting;
