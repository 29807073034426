import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { Input, Button, Switch, Form, Row, Col, Modal } from 'antd';
//import 'antd/dist/antd.css';
import './Chats.css';
import { email } from 'react-admin';
const { v4: uuidv4 } = require('uuid');
//const CHAT_ID = '11111';
//const USER_EMAIL = 'user@example.com';
let socket; // Объявляем переменную для хранения экземпляра сокета+
//const userId = '';
//const roomId = '';
let allusers = 0;
const userId = localStorage.getItem('UserId');
const searchParams = new URLSearchParams(window.location.search);
const roomId = searchParams.get('id');
const nameUser = localStorage.getItem('webname') || 'Неизвестный посетитель';
const uemail = localStorage.getItem('lastemail') || 'Нет';
const phone = localStorage.getItem('lastphone') || 'Нет';



const Webroomchat = (props) => {
  const { chatParams } = props;
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [socket, setSocket] = useState(null); // Состояние для хранения экземпляра сокета
  const [usersNum, setUsersNum] = useState(''); //состояние для количества пользователей
  const [isButton, setButton] = useState(true);
  const messagesEndRef = useRef(null); // Создание рефа для последнего элемента
  const [visible, setOpen] = useState(false);
 


  const handleCancel = () => {
    // Ваша логика обработки нажатия кнопки "Отмена" в удалении
    setOpen(false);
  };
  const handleOk = async () => {
    // Подтверждаем удаление
    setOpen(false);
  }


  const onFinish = (values) => {
    console.log('Received values:', values);
  };

  const delMessage = () => {
    setOpen(true);

  };

  const sendtlgrm = () => {


  };

  const linkMessage = () => {


  };

  const ignorMessage = () => {


  };

  const openCRM = () => {


  };





  // Функция отправки сообщения
  const sendMessage = () => {
    if (inputValue.trim() !== '' && socket) { // Проверяем, что введено непустое сообщение и сокет установлен
      const messageId = uuidv4();
      const unixTime = Math.floor(Date.now() / 1000);
      const now = new Date();
      const hhmmTime = now.getHours() + ':' + (now.getMinutes() < 10 ? '0' : '') + now.getMinutes();
      socket.emit('message', { prava: 0, message: inputValue, userId: userId, roomId: roomId, unixTime: unixTime, hhmmTime: hhmmTime, messageId: messageId, userName: nameUser, uemail: uemail, phone: phone  });
      setInputValue(''); // Очищаем поле ввода после отправки сообщения
    }
  };

  useEffect(() => {
  
    //nameuser = localStorage.getItem('webname');


    const newSocket = io('https://learninglive.ru:5500', {
      query: {
        roomId: roomId,
        userID: userId
      }
    });
  

  
    setSocket(newSocket); // Устанавливаем экземпляр сокета в состояние
    //newSocket.emit('join', roomId, userId);
    newSocket.emit('join', { roomId: roomId, userId: userId });
    // Подписываемся на прием сообщений
    newSocket.on("message", ({ data }) => {
      if (data.message) {
      setMessages((setMessages) => [...setMessages, data]);
      }
      if (data.numUsers && data.roomId === roomId) {
        setUsersNum(data.numUsers)
        allusers = data.numUsers;
      }
      console.log('********/////////////', allusers, data);
     // Меняем сообщение
   

    });

   
  
    // Закрываем соединение с сокетом при размонтировании компонента
    return () => {
      newSocket.close();
    };
  }, []);


  useEffect(() => {
    if(messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="webchat" style={{ border: '1px solid red' }}>
        
            <div className="webchatname" style={{  }}>
                  <div>
                    <>Участников: {usersNum} Вы {nameUser}</>
                  </div>
                  <div>

                  </div>
            </div>
        
            <div className="webchatmessage" style={{ }}>
                  
                              <div className="message-container_moder" style={{ }}>
                              {messages.map((message, index) => (
                                <div key={index} className="message" style={{  }}>
                                  <label style={{ fontWeight: 'bold' }}>{message.hhmmTime} {message.userName} tel:{message.phone} idcrm:{message.idcrm} email:{message.uemail} </label> 
                                  <label>{message.message}</label> {/* Предполагаем, что сообщение хранится в свойстве message */}
                                        <div className="moderbutton">
                                        <Button type="primary" htmlType="submit" size="small" style={{marginLeft: '5px'}} onClick={openCRM}>Открыть CRM</Button>
                                        <Button type="primary" htmlType="submit" size="small" style={{marginLeft: '5px'}} onClick={ignorMessage}>В игнор</Button>
                                        <Button type="primary" htmlType="submit" size="small" style={{marginLeft: '5px'}} onClick={linkMessage}>Отметить</Button>
                                        <Button danger type="primary" htmlType="submit" size="small" style={{marginLeft: '5px'}} onClick={delMessage}>Удалить сообщение</Button>
                                        <Button type="primary" htmlType="submit" size="small" style={{marginLeft: '5px'}} onClick={sendtlgrm}>Написать в TG</Button>
                                        </div>
                                </div>
                              ))}
                              <div ref={messagesEndRef} /> {/* Реф для последнего элемента */}
                            </div>

            </div>  
        
            <div className="webchatinput" style={{  }}>
                    <div className="webchatinputbutton" style={{  }}>
                        <div className="webchatinput_100">
                                <Input
                                placeholder="Введите Ваше сообщение"
                                style={{ width: '100%' }}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)} // Обработчик изменения значения в поле ввода
                                />
                        </div>
                        <div>
                              {isButton ? (
                                <Button type="primary" htmlType="submit" style={{marginLeft: '5px'}} onClick={sendMessage}>Отправить</Button>
                              ) : (
                                <Button type="dashed" disabled style={{marginLeft: '5px'}}>
                                  Закрыто
                                </Button>
                              )}
                                
                        </div>
                    </div>
                    <div className="webchatinputlabel" style={{  }}>
                                <label>Версия для модераторов</label>
                    </div>
                
            </div>
            <div>
                <Modal
                  title="Подтвердить удаление комментария"
                  label= "Вот что удаляем"
                  open={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  
                </Modal>

                </div>

    </div>
);


};

export default Webroomchat;
