// apiRequests.js
const fetchUserData = async (surl) => {
    const token = localStorage.getItem('Token');
    if (token) {
      try {
        const response = await fetch(`https://learninglive.ru:5000/api/prava`, {
          headers: {
            authorization: token,
            surl: surl,
          },
        });
  
        const result = await response.json();
        //console.log('+++++++', result);
        return { data: result, status: response.status };
      } catch (error) {
        console.error('Error fetching user data:', error);
        return { error: 'Error fetching data' };
      }
    } else {
      console.error('No token found in local storage');
      return { error: 'No token found' };
    }
  };
  
  export default fetchUserData;
  